/* ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
PATTERNS - NAVIGATION
–––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

// Main Nav ------------------------------------------------------------------
.navbar {
  margin-bottom: 3.5em;
}

// .mainlogo {
//   display: inline-block;
// }

.navbar-default {
  background-color: #fff;
  border: none;
  border-bottom: solid 1px $grey-vlight;
  .navbar-brand {
    font-family: $secondaryFont;
    height: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    > img {
      display: inline-block;
      width: auto;
      height: 22px;
      padding-right: 8px;
    }
    &:hover {
      background-color: #eeeeee;
    }
  }
  .navbar-nav {
    font-family: $secondaryFont;
    font-size: 1.2em;
    text-transform: uppercase;
    > li {
      > a {
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;
        border-bottom: solid 1px $dark-tint;
        &:hover {
          background-color: #eeeeee;
        }
      }
      &:last-child {
          > a {
              border-bottom: none;
          }
      }
    }
    > .active {
      > a {
        color: #fff;
        background-color: $green-dark;
        &:hover, &:focus {
          color: #fff;
          background-color: $green-dark;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .navbar-nav {
        font-size: 0.8em;
        > li {
          > a {
            text-align: left;
            border-bottom: none;
          }
        }
    }
  }
}

.navbar-toggle {
    margin-top: 12px;
    margin-bottom: 12px;
}

.nav-pills {
  font-family: $secondaryFont;
  a {
      border-bottom: none;
      &:hover, &:focus {
          border-bottom: none;
          color: $black;
      }
  }
}
