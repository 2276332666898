/* ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
COMPONENTS
–––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
#mainContent {
  max-width: 1000px;
  margin-bottom: 60px;
}

#expandoLinks {}

#stickyLinks {
  background-color: #fff;
}

.sidebar {
  position: relative;
  margin-top: -30px;
  margin-bottom: 1.5em;
  z-index: 1001;
  @media only screen and (min-width: 768px) {
      margin-top: 0;
  }
}

.container-fluid {
  @media only screen and (min-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (min-width: 1200px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.section-block {
  @media only screen and (min-width: 480px) {
    padding-left: 30px;

    .h2,
    h2 {
      margin-left: -30px;
    }
  }
  @media only screen and (min-width: 768px) {
    padding-left: 50px;

    .h2,
    h2 {
      margin-left: -50px;
    }
  }
}

.skip-link {
  position: absolute;
  top: -100px;
  left: 0;

  &:focus {
    top: 0;
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 17px 27px;
    background-color: $blendgreen;
    z-index: 1;
  }
}

.lead {
  font-size: 1.4em;
  line-height: 1.75;
}

.form-group {
  margin-bottom: 1.5em;
}

.has-feedback label ~ .form-control-feedback {
  @media only screen and (min-width: 1440px) {
    top: 33px;
  }
  @media only screen and (min-width: 1600px) {
    top: 36px;
  }
}

.has-error .form-control {
  &:focus {
    outline: 4px solid $red;
    outline-offset: -2px;
  }
}

.help-block {
  font-size: 0.85em;
}

.message-block {
  font-size: 0.85em;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: $red;
}

.has-error .form-control-feedback {
  color: $red;
}

.alert-danger {
  ol,
  p,
  ul:not(.nav) {
    a {
      color: $red;

      &:focus,
      &:hover {
        background-color: $red;
        border-bottom: solid 2px $red;
        text-decoration: none;
        color: #fff;
      }
      &:focus {
        outline: 4px solid $red;
        // outline-offset: -2px;
      }
    }
  }
}

.well {
  position: relative;
  background-color: #fff;
  padding: 25px 30px 15px;
  border-color: $grey-vlight;
  border-width: 4px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media only screen and (min-width: 768px) {
    padding: 35px 40px 20px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.badge {
  font-size: 60%;
  background-color: $black;
  padding-right: 10px;
  border-radius: 0 15px 15px 0;
  margin-top: -0.1em;
}

.badge--critical {
    background-color: $red;
}

.badge--important {
    background-color: $green-dark;
}

.dl-split {
    display: flex;
    flex-wrap: wrap;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    > * {
        flex: 0 0 33%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: solid 1px $grey-vlight;
    }

    > dt {
        padding-right: 15px;
        &:first-of-type {
            border-top: none;
        }
    }

    > dd {
        flex-basis: 67%;
        padding-left: 15px;
        &:first-of-type {
            border-top: none;
        }
    }
}

.inline-icon {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 1em;
    margin-top: -0.1em;
    margin-right: 1px;
    margin-left: 1px;
}

.slider-wrap {
  position: relative;
  background-color: $black;
  .owl-item {
    visibility: hidden;
    opacity: 0;
    @include transition(opacity, 0.5s, ease-in-out);
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  .owl-nav {
    position: absolute;
    bottom: 0px;
    left: 10px;
    .owl-next, .owl-prev {
      @extend .btn;
      @extend .btn-primary;
    }
  }
  .play {
    position: absolute;
    bottom: 0px;
    right: 10px;
    z-index: 1;
  }
}

.slide-text-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10%;
  bottom: 10px;
  left: 10%;
  color: #fff;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  // background-color: rgba(0,0,0,0.6);
  .slide-text {
    flex: 0 0 auto;
    width: 100%;
  }
  .h3 {
    margin-top: auto;
  }
}

.owl-carousel {
  margin-bottom: 1.5em;
}

.radiobtns {
    legend {
        display: block;
        border-bottom: none;
    }
    .radio {
        display: inline-block;
        padding-left: 0;
        margin: 0 -5px 0 0;

        &:last-child {
            padding-right: 0;
        }

        label {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            text-transform: uppercase;
            padding: 11px 14px;
            @include transition(all, 0.15s, ease-in-out);

            &:focus,
            &:hover {
                color: $white;
                background-color: $green-dark;
            }
        }

        input[type="radio"] {
            opacity: 0;
            margin-left: 0;
            z-index: 1;
            cursor: pointer;

            &:focus + label {
                outline: 1px auto $green-dark;
                outline-offset: 1px;
                outline-style: solid;
            }

            &:disabled {
                cursor: not-allowed;
            }

            &:disabled + label {
                opacity: 0.65;
                cursor: not-allowed;
            }
        }
    }

    input[type="radio"] {
        &:checked + label {
            color: $white;
            background-color: $green-dark;
        }
    }
    @media only screen and (min-width: 768px) {
        margin-bottom: 15px;
    }
    @media only screen and (min-width: 1420px) {
        .radio {
            margin-left: 3px;
        }
    }
    @media only screen and (min-width: 1600px) {
        .radio {
            margin-left: 10px;
        }
    }
}

.back-to-top {
    display: inline-block;
    color: $black;
    width: 44px;
    height: 44px;
    text-indent: 9000px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 22px;
    background-color: #fff;
    background: rgba(255,255,255,0.85) url($imageDir + 'icon-arrow-up.svg') no-repeat center;
    background-size: 24px;
    border: none;

    position: fixed;
    right: 15px;
    bottom: 10px;
    z-index: 1002;

    &:hover, &:focus, &:active {
        background-color: $green-light;
        border: none;
        transform: scale(1.1);
    }
    @media only screen and (min-width: 1024px) {
        right: 30px;
    }
    @media only screen and (min-width: 1200px) {
        right: 60px;
    }
    @media only screen and (min-width: 1440px) {
        right: 72px;
        bottom: 15px;
    }
    @media only screen and (min-width: 1600px) {
        right: 80px;
        bottom: 21px;
    }
}

.footer {
  color: $grey-light;
  background-color: #171717;
  padding-top: 1.5em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $grey-light;
  }
}
// image placement -----------------------------------------------------------

.alignright {
  float: none;
  display: block;
  max-width: 100%;
  margin: 2px auto 15px;
  @media only screen and (min-width: 375px) {
    float: right;
    max-width: calc( 50% - 15px );
    height: auto;
    margin: 7px 0 8px 15px;
    clear: both;
  }
  @media only screen and (min-width: 768px) {
    // max-width: calc( 50% - 20px );
    margin: 7px 0 8px 30px;
  }
}

.alignleft {
  float: none;
  display: block;
  max-width: 100%;
  height: auto;
  margin: 2px auto 15px;
  @media only screen and (min-width: 375px) {
    float: left;
    max-width: calc( 50% - 15px );
    margin: 7px 15px 8px 0;
    clear: both;
  }
  @media only screen and (min-width: 768px) {
    // max-width: calc( 50% - 20px );
    margin: 7px 30px 8px 0;
  }
}

.alignnone {
  display: inline-block;
  max-width: 100%;
  height: auto;
  // margin: 7px auto 15px;
  margin: 7px 15px 15px 0;
}

.aligncenter {
  display: block;
  width: 100%;
  height: auto;
  margin: 7px auto 15px;
}
// utility classes -----------------------------------------------------------

.text-center-xs {
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: left;
  }
}

.text-right-md {
  @media only screen and (min-width: 768px) {
    text-align: right;
  }
}

.pull-right-sm {
  @media only screen and (min-width: 768px) {
    float: right;
  }
}

.space-btm {
  margin-bottom: 1.565em;
}

.nospace {
  margin: auto !important;
}

.nowrap {
  white-space: nowrap;
}

.noborder {
  border: none !important;
}

.nopad {
  padding: 0 !important;
}

.hover-it {
  @include box-shadow(0px, 0px, 0px, rgba(0,0,0,0.0));
  @include transition(box-shadow, 0.15s, ease-in-out);

  &:hover {
    @include box-shadow(0px, 3px, 7px, rgba(0,0,0,0.3));
  }
}
.up-shift {
  margin-top: -40px;
  @media only screen and (min-width: 1200px) {
    margin-top: -50px;
  }
}

.contrast-block {
  position: relative;
  padding: 0;
  min-height: 300px;
  border: 2px solid black;
  .block-content {
    padding: 0 0 5px 15px;
    position: absolute;
    bottom: 0;
    background-color: transparent;
    color: white;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  &.fail {
    background-color: none;
  }
  &.pass {
    background-color: black;
  }
  &.working-background-image {
    background-image: url('../images/lake01.jpg');
    background-size: cover;
  }
  &.disabled-background-image {
    background-image: url('/');
  }
}
