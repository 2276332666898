/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

.print-logo {
   display: none;
   width: 150px;
   height: 35px;
   margin-bottom: 30px;
}

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: none;
    }

    a[href]:after {
        content: "";
        font-size: 80%;
    }

    .cell a[href]:after {
        content: "";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote, li {
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    p, .time, li {
        font-size: 12px;
        line-height: 18px;
    }

    hr {
        border-color: #000 !important;
    }

    .hide-for-print {
        display: none !important;
    }

    .print-width {
        width: 100%;
        left: 0;
    }
    
    .print-logo {
        display: block;
    }
}
