/* ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
PATTERNS
–––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.table--stack {
    display: block;
    caption {
        display: block;
        color: $brand-primary;
        font-family: $secondaryFont;
        font-weight: 600;
        text-transform: uppercase;
    }
    thead {
        display: none;
    }
    tbody, tfoot, tr, th, td {
        display: block;
    }
    tr {
        padding: 15px;
        border: solid 1px #cccccc;
        margin-bottom: 20px;
    }
    th, thead th {
        font-size: 1rem;
        font-family: $secondaryFont;
        font-weight: 600;
        line-height: 1.2;
        text-transform: uppercase;
        min-width: auto;
        background-color: #dddddd;
        padding: 0.6rem 0.5em 0.5em;
        border-top: none;
        border-bottom-color: #cccccc;
        &:after {
    		content: "";
    		display: table;
    		clear: both;
    	}
    }
    td {
        text-align: right;
        min-width: auto;
        padding: 0.25em 0.5em;
        border-top: 0px;
        &:after {
    		content: "";
    		display: table;
    		clear: both;
    	}
        &:first-child {
            border-top: none;
        }
    }
    .t-label {
        font-size: 1rem;
        font-family: $secondaryFont;
        font-weight: 600;
        text-transform: uppercase;
        float: left;
    }
    @media only screen and (min-width: 768px) {
        display: table;
        border-bottom: solid 1px #999999;
        caption {
            display: table-caption;
        }
        thead {
            display: table-header-group;
        }
        tbody {
            display: table-row-group;
        }
        tfoot {
            display: table-footer-group;
        }
        tr {
            display: table-row;
            padding: 0;
            border: none;
            margin-bottom: 0;
        }
        th, td {
            display: table-cell;
            text-align: center;
            min-width: 30px;
        }
        td {
            border-top: solid 1px #999999;
            vertical-align: middle;
            &:first-child {
                border-top: solid 1px #ddd;
            }
        }
        .t-label {
            display: none;
        }
    }
    @media only screen and (min-width: 992px) {
        th, thead th {
            padding: 0.75rem;
        }
        td {
            padding: 0.75rem;
        }
    }
}
