/* ==========================================================================
   base SCSS
   ========================================================================== */
//default type styles

html {
  // text-align: center;
  // background: #eee;
}

body {
  // min-width: 320px;
  // max-width: 2000px;
  // display: inline-block;
  // margin: auto;
  // text-align: left;
  // line-height: 1.4;
}

body {
  position: relative;
  font-family: $primaryFont;
  // font-weight: 400;
  font-size: 14px;
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1440px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 18px;
  }
}

p {
  // font-size: 1em;
  // font-weight: 400;
  margin-bottom: 1.5em;
}

.h1,
h1 {
  font-family: $secondaryFont;
  // font-size: 3em;
  font-weight: 800;
  letter-spacing: -2px;
  margin-top: 0;
  // margin-bottom: 0.35em;
  // line-height: 1.05;
  // @media only screen and (min-width: 768px) {
  //     font-size: 3em;
  // }
}

.h2,
h2 {
  font-family: $secondaryFont;
  // font-size: 2.4em;
  font-weight: 800;
  // line-height: 1.05;
  letter-spacing: -1px;
  margin-top: 1.5em;
  // margin-bottom: 28px;
  // @media only screen and (min-width: 768px) {
  //     font-size: 2.4em;
  // }
}

.h3,
h3 {
  font-family: $secondaryFont;
  // font-size: 1.4em;
  font-weight: 800;
  // text-transform: uppercase;
  // line-height: 1.1;
  margin-top: 2em;
  // margin-bottom: 1.0em;
}

.h4,
h4 {
  font-family: $secondaryFont;
  // font-size: 1.4em;
  font-weight: 800;
  // line-height: 1.1;
  margin-top: 2.5em;
  margin-bottom: 1.25em;
  // margin-bottom: 1.0em;
}

.h5,
h5 {
  color: $green-dark;
  font-family: $secondaryFont;
  font-weight: 800;
  letter-spacing: 1px;
  // margin-bottom: 1.565em;
  text-transform: uppercase;
}

.h6,
h6 {
  font-family: $secondaryFont;
  // font-size: 0.75em;
  // line-height: 1.5;
  font-weight: 800;
  // margin-top: 0;
  // margin-bottom: 9px;
  // text-transform: uppercase;
}

.btn,
.btn.active,
.btn.active.focus,
.btn.focus,
.btn:active,
.btn:active.focus,
.form-control,
.navbar-toggle,
a,
input[type="checkbox"],
input[type="file"],
input[type="radio"] {
  &:focus {
    outline: 4px solid $green-dark;
    outline-offset: -2px;
  }
}

.form-control {
  &:focus {
    border-color: $green-dark;
  }
}

ol,
p,
ul:not(.nav, .dropdown-menu) {
  a {
    @include transition(all, 0.2s, ease-in-out);
    text-decoration: none;
    border-bottom: solid 2px currentColor;

    &:focus,
    &:hover {
      background-color: $green-dark;
      border-bottom: solid 2px $green-dark;
      text-decoration: none;
      color: #fff;
    }
  }
}

ul {
  // padding-left: 27px;
  margin-bottom: 1.5em;
  li {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  //     margin-bottom: 13.5px;
  //     ul {
  //         margin-top: 13.5px;
  //     }
  }
}

ol {
  // padding-left: 27px;
  // margin-bottom: 1.565em;
  li {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  //     margin-bottom: 13.5px;
  //     ol {
  //         margin-top: 13.5px;
  //     }
  }
}

hr {
  border-top: 1px solid $grey-light;
  margin: 30px 0;
  clear: both;
  @media only screen and (min-width: 1200px) {
      margin: 45px 0;
  }
}

label, .label {
    color: $black;
  font-size: 1em;
  font-family: $secondaryFont;
  // text-transform: uppercase;
  font-weight: normal;
  padding: 0;
  margin-bottom: 5px;
}

.form-inline {
    label, .label {
        margin-right: 10px;
    }
}

legend {
  color: $green-dark;
  font-family: $secondaryFont;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: none;
  margin-bottom: 12px;
}
fieldset {
  fieldset {
    legend {
      color: $black;
      font-size: 1em;
      font-family: $secondaryFont;
      font-weight: normal;
      text-transform: none;
      letter-spacing: 0px;
      border-bottom: solid 1px $grey-light;
      margin-bottom: 12px;
    }
  }
}

figcaption {
  font-family: $secondaryFont;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: solid 4px $grey-vlight;
}

table {
  th {}

  tr {}
}

code {
  color: $black;
  background-color: $green-vlight;
  border: solid 1px $green-light;
  font-weight: normal;
}

pre {
  color: $black;
  background-color: $green-vlight;
  border-color: $green-light;
  margin-bottom: 1.5em;
}

small {
  font-size: 80%;
  font-family: $secondaryFont;
  color: #666;
}

blockquote {
  font-size: 0.85em;
  border-left-color: $grey-vlight;
  margin-bottom: 1.5em;
}
