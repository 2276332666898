/* ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
MIXINS
–––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$prefixes: -webkit-, -moz-, -o-, "";

@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin removeFocusOutline(){
    &:focus {
        outline: 0;
    }
}

@mixin noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    @each $prefix in $prefixes {
        #{$prefix}-user-select: none;
    }
}

@mixin translate3d($x, $y, $z) {
    @each $prefix in $prefixes {
        #{$prefix}transform: translate3d($x, $y, $z);
    }
}

@mixin rotate2d($degrees) {
    @each $prefix in $prefixes {
        #{$prefix}transform: rotate($degrees);
    }
}

@mixin transitions($type, $speed, $ease){
  @each $prefix in $prefixes {
    #{$prefix}transition: $type $speed $ease;
  }
}

@mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
  -webkit-box-shadow: $horizontal $vertical $blur $color;
  -moz-box-shadow: $horizontal $vertical $blur $color;
  box-shadow: $horizontal $vertical $blur $color;
}

@mixin text-shadow($horizOffset, $vertOffset, $blur, $color) {
    -webkit-text-shadow: $horizOffset $vertOffset $blur $color;
    -moz-text-shadow: $horizOffset $vertOffset $blur $color;
    -ms-text-shadow: $horizOffset $vertOffset $blur $color; /* IE 10+ */
    -o-text-shadow: $horizOffset $vertOffset $blur $color;
    text-shadow: $horizOffset $vertOffset $blur $color;
}
